import apiService from "../../errorHandling/appService";
export const getHeaderTokenFunc = async (
  parentContext: any, usedToken: any
): Promise<any> => {
  try {

    const auth = parentContext.authContext;
    const accounts = auth.getAllAccounts();
    const resource: any = { scopes: [process.env.REACT_APP_API_RESOURCE] };
    if (accounts.length > 0) {
      auth.setActiveAccount(accounts[0]);
    }
    let refreshedToken = usedToken;
    if (shouldRefreshToken(usedToken)) {
      try {
        refreshedToken = await auth?.acquireTokenSilent(resource);
      } catch (refreshError) {
        console.error("Error refreshing token:", refreshError);      
        throw refreshError;
      }
    }
    const request = {
      headers: {
        Authorization: "Bearer " + refreshedToken.accessToken,
      },
    };
    
    return { request, token: refreshedToken };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

const shouldRefreshToken = (token: any): boolean => {  
  const expirationTime = token?.expiresOn ? new Date(token.expiresOn).getTime() : null;
  if (expirationTime) 
  {
    const currentTime = new Date().getTime();
    const thirtyMinutesInMilliseconds = parseInt(process.env.REACT_APP_ACCESS_TOKEN_EXPIRY_DURATION) * 60 * 1000;
    return currentTime >= expirationTime - thirtyMinutesInMilliseconds;
  }
  return true;
}

export const getConsumptionAPI = async (
  url: string,
  parentContext: any,
  responseType: string = 'json' // Default to 'json' if not provided
): Promise<any> => {
  try {
    const auth = parentContext.authContext;
    const accounts = auth.getAllAccounts();
    const resource: any = { scopes: [process.env.REACT_APP_API_RESOURCE],};
    const apiEndPoint = `${process.env.REACT_APP_Consumption_API_ENDPOINT}`;
    const apiURL = apiEndPoint + url;
    if (accounts.length > 0) {
      auth.setActiveAccount(accounts[0]);
    }
    let  token = await auth?.acquireTokenSilent(resource);
    // Check if the token needs to be refreshed
    if (shouldRefreshToken(token)) {
      token = await auth?.acquireTokenSilent(resource); // Refresh token
    }

    const request = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
        responseType: responseType // Include responseType in headers
      },
    };
    // Use the generic apiService for the request
    const response = await apiService(apiURL, 'GET', null, request.headers);
    return response;

  } catch (err) {   
    return err?.response || err;
  }
};


export const postConsumptionAPI = async (
  url: string,
  postData: object | FormData,
  parentContext: any
): Promise<any> => {
  try {
    const auth = parentContext.authContext;
    const accounts = auth.getAllAccounts();
    const resource: any = { scopes: [process.env.REACT_APP_API_RESOURCE] };
    const apiEndPoint = `${process.env.REACT_APP_Consumption_API_ENDPOINT}`;
    const apiURL = apiEndPoint + url;
    if (accounts.length > 0) {
      auth.setActiveAccount(accounts[0]);
    }
    const token = await auth?.acquireTokenSilent(resource);

    let headers = {
      Authorization: "Bearer " + token.accessToken,
    };

    let request: any = {
      headers: headers,
      method: 'POST',
    };

    if (postData instanceof FormData) {
     
      headers['Content-Type'] = 'multipart/form-data';
      request.body = postData;
    } else {
      headers['Content-Type'] = 'application/json';
      request.body = JSON.stringify(postData);
    }

    let response = await apiService(apiURL, request.method, request.body, request.headers);
    return response;
  } catch (err) {   
    
    return err?.response || err;
  }
};


export const putConsumptionAPI = async (
  url: string,
  putData: object,
  parentContext: any
): Promise<any> => {
  try {
    const auth = parentContext.authContext;
    const accounts = auth.getAllAccounts();
    const resource: any = { scopes: [process.env.REACT_APP_API_RESOURCE],};
    const apiEndPoint = `${process.env.REACT_APP_Consumption_API_ENDPOINT}`;
    const apiURL= apiEndPoint+url;
    if (accounts.length > 0) {
      auth.setActiveAccount(accounts[0]);
    }
    const token = await auth?.acquireTokenSilent(resource);
    const request = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
      },
    };
    let response = await apiService(apiURL, 'PUT', putData,request.headers);
    return response;
  } catch (err) {
    
    return err?.response || err;
  }
};

export const deleteConsumptionAPI = async (
  url: string,
  parentContext: any
): Promise<any> => {
  try {
    const auth = parentContext.authContext;
    const accounts = auth.getAllAccounts();
    const resource: any = { scopes: [process.env.REACT_APP_API_RESOURCE],};
    const apiEndPoint = `${process.env.REACT_APP_Consumption_API_ENDPOINT}`;
    const apiURL= apiEndPoint+url;
 
    if (accounts.length > 0) {
      auth.setActiveAccount(accounts[0]);
    }
    const token = await auth?.acquireTokenSilent(resource);
    const request = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
      },
    };
    let response = await apiService(apiURL, 'DELETE', null,request.headers);
    return response;
  } catch (err) {
  
    return err?.response || err;
  }
};

export const getAccessToken = async (
  // url: string,
  parentContext: any
): Promise<any> => {
  try {
    const auth = parentContext.authContext;

    const resource: any = process.env.REACT_APP_Consumption_API_RESOURCE_ID;


    const token = await auth?.acquireTokenSilent(resource);  
    return token.accessToken;

  } catch (err) {
   
    return err?.response || err;
  }
};


export const getSalesUnitConsumptionAPI = async (
  url: string,
  parentContext: any
): Promise<any> => {
  try {
    const auth = parentContext.authContext;
    const accounts = auth.getAllAccounts();
    const resource: any = { scopes: [process.env.REACT_APP_API_RESOURCE],};
    const apiEndPoint = `https://mcapshelpexternalapidev.azurewebsites.net/`;
   const apiURL= apiEndPoint+url;
    if (accounts.length > 0) {
      auth.setActiveAccount(accounts[0]);
    }
    const token = await auth?.acquireTokenSilent(resource);
    const request = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
      },
    };
 
    const response = await apiService(apiURL,'GET',null,request.headers)
    return response;
  } catch (err) {
   
    return err?.response || err;
  }
};