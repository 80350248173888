import { injectIntl } from "react-intl";
import './FooterMain.css';

const FooterMainComponent = ({ selectedActiveTab }) => {
  console.log('selectedActiveTab', selectedActiveTab);

  const renderMain = (): JSX.Element => {
    return (
      <>
        <footer>
          <div className={`footer-area ${selectedActiveTab === '1' ? 'full-width' : ''}`}>
            <a href="https://privacy.microsoft.com/en-us/data-privacy-notice" target="_blank" rel="noopener noreferrer">
              Data Privacy Notice
            </a>
            <p>Microsoft confidential</p>
          </div>
        </footer>
      </>
    );
  };

  return renderMain();
};

export const FooterMain = injectIntl(FooterMainComponent);
