import React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { SETChileComponentName, SETHasServiceRequestTypeLogData, SETIsCustomeLog, SETLogEventData, SETServiceRequestTypeLogData } from "../../../../core/store";
import { useDispatch } from "react-redux";
import FooterContent from "./FooterContent";
const PopUpModal = ({
  
  isVisible = false,
  onConfirm = (e) => {},
  onCancel = () => {},
  onHide,
  itemId = "",
  dialogTitle = "",
  dialogSubText = "",
  buttonsCount = 0,
  buttonTextOne = "",
  buttonTextTwo = "",
  options = [],
  modalValue = "",
  openWebForm = (e) => {},
  openModal = (e,f) => {}
}) => {
 
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [value, setValue] = React.useState(modalValue);
  const [openInNewTab, setOpenInNewTab] = React.useState(false);
  const reduxDispatch = useDispatch();

  const handleChange = (event, option) => {
   
    setSelectedOption(option);
    setValue(option.value);
    setOpenInNewTab(option.openinBrowserTab);
    isVisible = false;
  };
  const handleKeyDownConfirm = (event,id) => {
    if (event.key === 'Enter') {
      onConfirm(id);
    }
  };
  const handleKeyDownCancel = (event) => {
    if (event.key === 'Enter') {
      onCancel();
    }
  };
  const handleKeyDownGetStarted = (event) => {
    if (event.key === 'Enter') {
      onGetStarted();
    }
  };
  const onGetStarted = async () => {
    
    // Parse the URL
    const urlParams = new URLSearchParams(value);

    // Check if the URL contains the requestType parameter
    if (urlParams.has("requestType")) {
      // Get the value of the requestType parameter
      const requestType = urlParams.get("requestType");

      const formattedRequestType = requestType.replace(/\s/g, "").toLowerCase();
      const formattedSelectedOption = selectedOption.text
        .replace(/\s/g, "")
        .toLowerCase();
      console.log(requestType);
      // Check if the requestType meets your criteria
      if (selectedOption && formattedRequestType === formattedSelectedOption) {
        // Open the webform or perform any action
        setSelectedOption(null);
        openWebForm(formattedRequestType);
        onCancel();
      }
    } else {
      setSelectedOption(null);
      openModal(value, openInNewTab);
      onCancel();
    }
     // Start Event Logging //
    reduxDispatch(SETChileComponentName('Dialog '+ dialogTitle));
   
    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    reduxDispatch(SETLogEventData(
    {
      elementName:"Request Type dropdown Start",
      elementId:"",
      action:"click",
      message : `User click on Request Type dropdown Start`,
    }
   ));
    reduxDispatch(SETServiceRequestTypeLogData(
    {ServiceName: dialogTitle,
    RequestType: selectedOption.text ||"",
    TileName:'',
    IsNonIrisService:'true',
    IrisUtterance:''
    }
    ));
    reduxDispatch(SETIsCustomeLog(true));
  // End Event Logging //    
  };
  const handleKeyDownClose = (event) => {
    if (event.key === 'Enter') {
      onClose();
    }
  };
  const onClose = () => {
    setSelectedOption(null);
    onCancel();
  };

   

  return (
    <Dialog

      hidden={!isVisible}
      onDismiss={() => {
        onHide();
        setSelectedOption(null);
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogSubText,
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 450, maxHeight: "auto" } }, 
        // isDarkOverlay:false
      }}
      styles={(props) => ({ main: { boxShadow: "none" } })} // other props
      className="service-tile-model">
        <DialogFooter>
      <FooterContent
        buttonsCount={buttonsCount}
        options={options}
        selectedOption={selectedOption}
        handleChange={handleChange}
        dialogTitle={dialogTitle}
        dialogSubText={dialogSubText}
        buttonTextOne={buttonTextOne}
        buttonTextTwo={buttonTextTwo}
        onGetStarted={onGetStarted}
        onClose={onClose}
        onCancel={onCancel}
        onConfirm={onConfirm}
        itemId={itemId}
        handleKeyDownGetStarted={handleKeyDownGetStarted}
        handleKeyDownClose={handleKeyDownClose}
        handleKeyDownCancel={handleKeyDownCancel}
        handleKeyDownConfirm={handleKeyDownConfirm}
      />
      </DialogFooter>
    </Dialog>
  );
};

export default PopUpModal;
