import "./AppFooter.css";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { classNamesFunction } from "@fluentui/react/lib/Utilities";
import {
  getCurrentTheme,
  setIsBotError,
  setIsLoader,
  settitleLoaderName,
} from "../../../core/store";
import { getStyles } from "./AppFooter.styles";
import { FooterProps } from "./AppFooter.types";
import { ServiceContext } from "@msx/platform-services";
import { ChatBot, ExtendedChatBotProps } from "./ChatBot";
import { IRISBotSettings, getIrisSelfHelp } from "./IrisSelfHelpContext";
import { checkIrisUtterance, checkTileParameterScenario, finalIrisUtterance, finalSearchTerm, finalTileName, isIrisMenuINDOM} from "./getFunctionsForFooter";


const getClassNames = classNamesFunction<any, any>();
let classes: any;

const AppFooterComponent: React.FC<FooterProps> = (props) => {

  let irisBot=false
  const reduxDispatch = useDispatch();

  const [irisContext, setIrisContext] = useState("");
  const [tileName, setTileName] = useState("");
  const [iriscontext, setcontext] = useState("");

  const [searchTerm, setSearchTerm] = useState(null);
  const [irisTokenCreated, setIrisTokenCreated] = useState(null);
  const context = React.useContext(ServiceContext);
  const currentUserData = props.currentUserData;
  
  useEffect(() => {
  checkIrisUtterance(
    props.irisUtterance,
    props.setShowBot,
    setcontext,
    setTileName,
    props.tileName,
    props.setChatBotClicked,
    setIrisContext
  );
  }, [props.irisUtterance, props.setIrisAppName,props.formDataToIris, props.tileName,props.chatBotClicked]);




  useEffect(() => {
    checkTileParameterScenario(props.finalTileObject,setIrisContext,setSearchTerm,setTileName);
  }, [props.finalTileObject["isIncidentTile"],props.tileName === ""]);

 

  useEffect(() => {
    isIrisBotClicked();
  }, [props.irisBotIconClicked]);

  const isIrisBotClicked = ()=>{
    if (props.irisBotIconClicked === true) handleChatBotIconClick();
  }

  const theme = useSelector(getCurrentTheme);
  classes = getClassNames(getStyles, theme);



  const chatBotProps: ExtendedChatBotProps = {
    irisSelfHelp: iriscontext
      ? getIrisSelfHelp(
        finalIrisUtterance(irisContext,props.irisUtterance),
          currentUserData,
          IRISBotSettings.WORKSTREAM,
          finalSearchTerm(searchTerm,props.SearchTerm),
          props.formDataToIris,
          finalTileName(tileName,props.tileName)
        )
      : null,
    appName: props.setIrisAppName
      ? props.setIrisAppName
      : IRISBotSettings.APP_NAME,
    platform: IRISBotSettings.PLATFORM_NAME,
  };


 const checkIrisMenu = () => {
    if(!irisBot){
    const irisMenu = document.querySelector(".ms-layer");
    console.log(irisMenu, "irisMenu");
    isIrisMenuINDOM(irisMenu,reduxDispatch,setIsLoader,irisBot,checkIrisMenu);
  }
  };  

 
  const handleChatBotIconClick = async () => {
    reduxDispatch(setIsLoader(true));
    reduxDispatch(setIsBotError(false));
    reduxDispatch(settitleLoaderName(""));

    try {
      irisBot=false;
      checkIrisMenu();
      if ( context?.telemetryClient) {
        props.setShowBot(true);
        setcontext("set context");
        setIrisContext("");
        setTileName("");
        setIrisTokenCreated(null);
        props.setIrisBotIconClicked(false);
      }
    } catch (error) {
      reduxDispatch(setIsBotError(true));
      console.error("API Error:", error);
    }
  };

  const closeBot = () => {
    props.setShowBot(false);
    setIrisContext(null);
    setTileName(null);
    if (props.resetShellIrisContext) {
      props.resetShellIrisContext();
    }
    props.setIrisBotIconClicked(false);
  };

    const renderBot = () =>
  {
    const isRendered = props.ShowBot &&
    props.IsUserAuthorized && 
    chatBotProps.irisSelfHelp !== null;

     return <div>
     {isRendered &&(
         <ChatBot
           {...chatBotProps}
           closeBotHandler={closeBot}
           showBot={props.ShowBot}
           irisApiBaseUrl={process.env.REACT_APP_CHATBOT_BASE_URL}
           irisToken={props.irisToken}
           irisTokenCreated={irisTokenCreated}
           setIrisTokenCreated={setIrisTokenCreated}
         />
       ) }
   </div>

  }

  const renderMain = (): JSX.Element => {
    return (
      <>
      <footer
        className={classes.container}
        style={{ backgroundColor: "#f5f5f5" }}
      >
       { renderBot()}
      </footer>
      </>
    );
  };
  return renderMain();



};

export const AppFooter = injectIntl(AppFooterComponent);
