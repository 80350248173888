/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, lazy, Suspense } from "react";
import "./AppHeader.css";
import { injectIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  ICommandBarItemProps
} from "@fluentui/react/lib/CommandBar";
import {
  CoherenceHeader,
} from "@coherence-design-system/controls";
import {
  ServiceContext,
  ApplicationContext
} from "@msx/platform-services";
import { messages } from "./AppHeader.messages";
import { HeaderProps } from "./AppHeader.types";
import { Stack, Panel, PanelType} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { IAppConfig } from "../../App.config";
import {
  getNotifications
} from "../../../app/store";
import { withRouter } from "react-router-dom";
import fakebotdata from "../../../app/components/header/fakeBot.json";
import { getBotToken, getTitleLoaderName, getisBotError, getisLoader, setIsBotError, setIsLoader, settitleLoaderName } from "../../../core/store";
import { lazyRetry } from "../../utils/utils";

const EditProfile = lazy(() => lazyRetry(() => import('../editprofile/EditProfile').then(module => ({ default: module.EditProfile }))));
const Settings = lazy(() => lazyRetry(() => import('..').then(module => ({ default: module.Settings }))));
const FeedbackPanel = lazy(() => lazyRetry(() => import('../feedback/FeedbackPanel')));
const AboutHelp = lazy(() => lazyRetry(() => import('../help/AboutHelp').then(module => ({ default: module.AboutHelp }))));

const AppHeaderComponent: React.FC<HeaderProps> = (props) => {
  let irisBotchat=false
  const getToken = useSelector(getBotToken)
  const loaderName = useSelector(getTitleLoaderName)
  const isBotLoader = useSelector(getisLoader)
  const isBotError = useSelector(getisBotError)
  const reduxDispatch = useDispatch();
  const { appState } = useContext(ApplicationContext);
  const context = React.useContext(ServiceContext);
  const notifications = useSelector(getNotifications);
  const [dismissOpenedPanel, setDismissOpenedPanel] = useState(false);
  const [dismissPanelButtonClick, setDismissPanelButtonClick] = useState(false);
  const [dismissHelpOpenedPanel, setDismissHelpOpenedPanel] = useState(false);
  const [dismissHelpPanelButtonClick, setDismissHelpPanelButtonClick] =
    useState(false);
  const {
    intl,
    isUserLoggedIn,
    appName,
    isAppReady,
    isEditableProfile,
  } = props;
  const [
    isNotificationsOpen,
    { setTrue: openNotificationsPanel, setFalse: dismissNotificationsPanel },
  ] = useBoolean(false);
  const appConfig = appState.appConfig as IAppConfig;
  const [isMCAPSChatBotStarted, setIsMCAPSChatBotStarted] = useState(false);
  const [isFeedbackPanelOpen, setIsFeedbackPanelOpen] = useState(false);
  const [fakebot, setFakebot] = useState<any>({});
  let intervalId: NodeJS.Timeout;
  useEffect(() => {
    dismissPanelButtonClick && setDismissOpenedPanel(false);
  }, [dismissPanelButtonClick]);

  useEffect(() => {
   if(dismissOpenedPanel){
    let intervalId = setInterval(() => {
      const closeButton = document.querySelector('.ms-Panel-closeButton');
      if (closeButton) {
        closeButton.setAttribute('aria-label', 'Close');
        clearInterval(intervalId);
      }
    }, 1000);
  }
    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear interval on component unmount
      }
    }; // Add return statement
   
  }, [dismissOpenedPanel]);
  
  useEffect(() => {
    dismissHelpPanelButtonClick && setDismissHelpOpenedPanel(false);
  }, [dismissHelpPanelButtonClick]);



  useEffect(()=>{
    const fetchJsonData = async () => {
      try{
        const jsonData = `${process.env.REACT_APP_BLOBSASSTART +"JSON/fakeBot.json"+ sastoken }`;
        const response = await fetch(jsonData);
        const data = await response.json();
        setFakebot(data);
      }catch(error ){
        const data = fakebotdata
        setFakebot(data);
        console.log("error in try in annuncements.tsx",error)
      }
    }
    fetchJsonData()
  },[])
  

  useEffect(() => {
    checkAndAddClass();
    attachButtonClick();
    obserMovments();
  }, []);
  useEffect(() => {
    reduxDispatch(setIsBotError(false));
    if (props.selectedActiveTab === '0') {
      checkIrisMenu();
    }
    else {
      reduxDispatch(setIsLoader(false));
    }
  }, [props.selectedActiveTab]);
  const sastoken = sessionStorage.getItem("SasTokenKey");

  const [images, setImages] = useState({
    copilot: process.env.REACT_APP_BLOBSASSTART + "BotIconthin.svg" + sastoken,
    copilotselected: process.env.REACT_APP_BLOBSASSTART + "BotIconthinblue.svg" + sastoken,
    fail: null,
    Magicimage: null,
    CoffeeLoader: null,
  });

  useEffect(() => {
    if (sastoken) {
      setImages({
        copilot: `${process.env.REACT_APP_BLOBSASSTART + "BotIconthin.svg" + sastoken}`,
        copilotselected: `${process.env.REACT_APP_BLOBSASSTART + "BotIconthinblue.svg" + sastoken}`,
        fail: `${process.env.REACT_APP_BLOBSASSTART + "fail.png" + sastoken}`,
        Magicimage: `${process.env.REACT_APP_BLOBSASSTART + "Magicimage.gif" + sastoken}`,
        CoffeeLoader: `${process.env.REACT_APP_BLOBSASSTART + "CoffeeLoader.gif" + sastoken}`,
      });
    }
  }, [sastoken]);

  const checkIrisMenu = () => {
    if(!irisBotchat){
    const irisMenu = document.querySelector(".ms-layer");
    console.log(irisMenu, "irisMenu");
    if (irisMenu?.innerHTML !== "") {
      setTimeout(() => {
        reduxDispatch(setIsLoader(false));
        irisBotchat=true;
      }, 9000);
    } else {
      reduxDispatch(setIsLoader(true));
      // If irisMenu is not found, retry after 3 seconds
      setTimeout(checkIrisMenu, 40);
    }
  }
  };

  useEffect(() => {
    // Check for the existence of the element every 500 milliseconds
    const intervalId = setInterval(checkAndAddClass, 500);

    // Detach the event listener when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    processNotificaitons();
    // eslint-disable-next-line
  }, [notifications]);

  const obserMovments = () => {
  const itemIris = document.getElementById("Iris");

  if (itemIris !== null) {
    setInterval(checkAndAddClass, 500);
    
  }
};
  
  const processNotificaitons = () => {
    let count = 0;
    if (notifications && notifications.length > 0) {
      notifications.forEach((item) => {
        if (item.status === "unread") count++;
      });
    }
  };



  
  
  const [isOpen, setIsOpen] = useState(false);

  const handleSignOut = () => {
    context.authClient.logOut();
  };

  const getHelpBody = () => {
    return (
      <Suspense
        fallback={
          <></>
        }>
        <AboutHelp isAppReady={isAppReady} />
      </Suspense>
    );
  };

  const getEditProfileBody = (onDismissProfilePanel: any) => {

    return (
      <Suspense
      fallback={
       <></>
      }
    >
      <EditProfile
        isEditableProfile={isEditableProfile}
        currentUserData={props.currentUserData}
        getUserIDByEmailId={props.getUserIDByEmailId}
        onDismissOpenedPanel={onDismissProfilePanel}
        handleChatBotIconClick ={props.handleChatBotIconClick}
      />
      </Suspense>
    );
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const panel = document.querySelector("#headerTab").parentNode.parentNode
          .parentNode as HTMLElement;
        panel.style.zIndex = "20000000";
      }, 100);
    }
  
  }, [isOpen]);

  useEffect(() => {

    if(getToken!=="null"){
      handleTabKey();
    }
  }, [getToken,isBotLoader]);

  useEffect(() => {
    if (props.selectedActiveTab === "0" && isOpen) {
      setTimeout(() => {
        const panel = document.querySelector("#headerTab");
      
        if (panel !== null) {
          let ele = panel.parentNode.parentNode.parentNode as HTMLElement;
          ele.style.zIndex = "200000000";
         
        } else {
          const iris = document.querySelector("#irisMenu");
         
          if (iris !== null) {
            let ele = iris.parentNode.parentNode.parentNode.parentNode
              .parentNode.parentNode.parentNode.parentNode.parentNode
              .parentNode as HTMLElement;
            ele.style.zIndex = "-1";
           
          }
        }
      }, 3000);
    }
  }, [props.selectedActiveTab, isOpen === true]);

  const handleDismissPanel = (event) => {
    
    event.stopPropagation();
    setDismissOpenedPanel(!dismissOpenedPanel);
    setDismissPanelButtonClick(true);
  };

  const handleOpenPanel = () => {
    setIsOpen(true);
    setDismissHelpOpenedPanel(false);
    setDismissOpenedPanel(true);
    // setDismissHelpOpenedPanel(false)
    if (dismissPanelButtonClick) {
      setDismissPanelButtonClick(false);
    }
  };


  const handleOpenHelpPanel = () => {
    setIsOpen(true);
    setDismissHelpOpenedPanel(true);
    if (dismissHelpPanelButtonClick) {
      setDismissHelpPanelButtonClick(false);
    }
  };

  const getSettingsBody = () => {
    return (
      <Suspense
        fallback={
          <></>
        }>
        <Settings onDismissPanel={handleDismissPanel} />
      </Suspense>
    );
  };

    

  const attachButtonClick = () => {
    const buttons = document.querySelectorAll(".ms-CommandBarItem-link");
    buttons.forEach((button) => {
      button.addEventListener("click", () => handleButtonClick(button));
    });

    // Cleanup event listeners when the component unmounts
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", () => handleButtonClick(button));
      });
    };
  };

  function checkAndAddClass() {
    // Select all elements with class "is-checked"
    let elementsWithClass = document.querySelectorAll(".is-checked");

    // Check the length of the NodeList
    let lengthOfIsCheckedClass = elementsWithClass.length;

    // If the length is zero, add the class to the div with id "iris"
    if (lengthOfIsCheckedClass === 0) {
      handleIrisIcon("Iris", true);
      setIsChecked("Iris", true);
    }
  }

  const handleButtonClick = (button) => {
    // Remove 'is-checked' class from the previously checked button
    let elementsWithClass = document.querySelectorAll(
      ".ms-CommandBarItem-link"
    );

    // Loop through the selected elements and remove the class
    elementsWithClass.forEach(function (element) {
      element.classList.remove("is-checked");
    });

    // Add 'is-checked' class to the clicked button
    button.classList.add("is-checked");
    

    // Call your function based on button.id

    if (button.id === "Iris") {
      handleIrisIcon("Iris", true);
      setIsChecked("Iris", true);
    } else {
      handleIrisIcon("Iris", false);
      setIsChecked("Iris", false);
    }

    checkAndAddClass();
  };

  const setIsChecked = (item, ischeked) => {
    let headericon = document.getElementById(item);
    if (headericon) {
      if (ischeked === true) {
        headericon.classList.add("is-checked");
      } else {
        headericon.classList.remove("is-checked");
      }
    }
  };

  const handleIrisIcon = (item, checked) => {
    if (item === "Iris") {
      let irisicons = document.getElementsByClassName("imgIris");
      if (irisicons.length > 0) {
        // Change the src attribute of each element with the specified class
        for (let i = 0; i < irisicons.length; i++) {
          irisicons[i]["src"] = checked === true ? images.copilotselected : images.copilot;
        }
      }
    }
  };

  const getFarItems = (): ICommandBarItemProps[] => {
    let items = [
      {
        key: "feedback",
        id: "header-feedback",
        text: intl.formatMessage(messages.feedback),
        ariaLabel: intl.formatMessage(messages.feedbackAriaLabel),
        iconOnly: true,
        iconProps: {
          iconName: "Emoji2",
          style: { color: "white" },
        },
        onClick: () => {
       
          setIsFeedbackPanelOpen(true);
   
        },
        styles: {
          root: {
            color: "black",
            backgroundColor: "white",
            fontSize: "14px",
            visibility: "visible",
            opacity: 1,
          },
        },
      },
      {
        key: "Iris",
        id: "Iris",
        text:"Click here to reset IRIS Copilot",
        //text:"IRIS Copilot",
        onClick: () => {
          props.handleChatBotIconClick();
        },
        onRenderIcon: () => (
          <img key={images.copilot} className="imgIris" src={images.copilot} alt="Iris Bot" />
        ),
        styles: {
          root: {
            color: "black",
            backgroundColor: "white",
            fontSize: "14px",
            visibility: "visible",
            opacity: 1,
          },
        },
      },
      {
        key: "chatBot",
        id: "header-chat-bot",
        text: intl.formatMessage(messages.chatBot),
        ariaLabel: intl.formatMessage(messages.financeSupportBotAriaLabel),
        onRenderIcon: () => (
          <img key={images.copilot} className="imgIris" src={images.copilot} alt="Copilot" />
        ),
        iconOnly: true,
        onClick: () => {
          setIsMCAPSChatBotStarted(!isMCAPSChatBotStarted);
        },
        styles: {
          root: {
            color: "black",
            backgroundColor: "white",
            fontSize: "14px",
            visibility: "visible",
            opacity: 1,
          },
        },
      },
      {
        key: "gudedTour",
        id: "header-guided-tour",
        text: intl.formatMessage(messages.retakeTour),
        ariaLabel: intl.formatMessage(messages.retakeTourAriaLabel),
        iconOnly: true,
        iconProps: {
          iconName: "ReadingMode",
        },
        onClick: () => {
        },
      },
      {
        key: "customNotificaiton",
        id: "header-my-notifications",
        text: intl.formatMessage(messages.settingsNotificationsTitle),
        ariaLabel: intl.formatMessage(messages.settingsNotificationsTitle),
        iconOnly: true,
        iconProps: {
          iconName: "Ringer",
        },
        onClick: handleNotificationsClick,
      },
    ];
    if (!isAppReady || !isUserLoggedIn) {
      items = items.filter(function (obj) {
        return obj.key !== "chatBot" && obj.key !== "gudedTour";
      });
      items = items.filter(function (obj) {
        return obj.key !== "customNotificaiton";
      });
      return items;
    }
    if (
      !appConfig.notificaitonConfig.active ||
      !appConfig.notificaitonConfig.customPanel
    ) {
      items = items.filter(function (obj) {
        return obj.key !== "customNotificaiton";
      });
    }
    if (!appConfig.botConfig.active) {
      items = items.filter(function (obj) {
        return obj.key !== "chatBot";
      });
    }
    if (!appConfig.guidedTourConfig.active) {
      items = items.filter(function (obj) {
        return obj.key !== "gudedTour";
      });
    }
    if (props.selectedActiveTab === "1") {
      items = items.filter((e) => {
        return e.key !== "Iris";
      });
    }
    return items;
  };
 
  const handleNotificationsClick = () => {
    openNotificationsPanel();
  };

  const handleChatBotIconClick = async (
    ) => {
      reduxDispatch(setIsBotError(false));
      if (props.selectedActiveTab === '0') {
        reduxDispatch(setIsLoader(true));
      }
      else {
        reduxDispatch(setIsLoader(false));
      }
      reduxDispatch(settitleLoaderName(""));
      if(getToken==null)
      {
        setTimeout(() => {
          reduxDispatch(setIsBotError(true));
        }, 4000);      
      }
      else
      {
        reduxDispatch(setIsLoader(false));
        reduxDispatch(setIsBotError(false));
      }     
     
    };

  const renderNotificationsPanel = () => {
    if (!appConfig.notificaitonConfig.active) return null;
    if (!appConfig.notificaitonConfig.customPanel) return null;

    const panelStyle = { root: { marginTop: "48px" } };
    return (
      <Panel
        isLightDismiss
        isOpen={isNotificationsOpen}
        type={PanelType.medium}
        onDismiss={dismissNotificationsPanel}
        closeButtonAriaLabel="Close"
        headerText={intl.formatMessage(messages.settingsNotificationsTitle)}
        styles={panelStyle}
      >
        {renderNotificationsPanelBody()}
      </Panel>
    );
  };

  const renderNotificationsPanelBody = (): JSX.Element => {
    return (
      <Stack horizontalAlign={"start"}>
        <Stack.Item>{"display notification items here"}</Stack.Item>
      </Stack>
    );
  };



  const renderMCAPSChatBot = (): JSX.Element => {
    return (
      <></>
    );
  };

  // Your existing code...

  const renderFullHeader = (): JSX.Element => {
    let profile = `${process.env.REACT_APP_BLOBSASSTART +"profile_logo.jpg"+ sastoken }`;
    return (
        <div className="appHeader-position">
          <CoherenceHeader
            headerLabel={"header"}
            appNameSettings={{
              label: appName,
              ariaLabel:appName,
            }}
            farRightSettings={{
              additionalItems: getFarItems(),
              
              helpSettings: {
                
                panelSettings: {
                  titleText: intl.formatMessage(messages.helpSettingsTitle),
                  body: getHelpBody(),
                  className: "aboutPanel",
                  id: "headerTab",
                  tabIndex: 0,
                  isOpen: dismissHelpOpenedPanel,
                  onClick: () => handleOpenHelpPanel(),
                 
                },
              },

              
              profileSettings: {
                panelSettings: {
                  fullName: props.user.name,
                  emailAddress: props.user.email,
                  imageUrl: profile,
                  logOutLink: "#",
                  onLogOut: () => handleSignOut(),
                  onRenderFooter: () => {
                    return (
                      <a
                        href="https://privacy.microsoft.com/en-us/privacystatement"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Privacy Notice
                      </a>
                    );
                  },
                  body: getEditProfileBody(handleDismissPanel),
                  isOpen: dismissOpenedPanel,
                  onClick: () => handleOpenPanel(),
                  id: "headerTab",
                  className: "ProfileTab",
                },
              },
            }}
            dismissOpenedPanel={dismissOpenedPanel || dismissHelpOpenedPanel}
            onDismissOpenedPanel={() => {
              setDismissPanelButtonClick(true);
              setDismissHelpPanelButtonClick(true);
            }}
          />
        </div>
    );
  };

  const renderInitialHeader = (): JSX.Element => {
    return (
      <CoherenceHeader
        headerLabel={"header"}
        appNameSettings={{
          label: appName,
        }}

       
      />
    );
  };

  const renderNotLoggedInHeader = (): JSX.Element => {
    return (
      <CoherenceHeader
        headerLabel={"header"}
        appNameSettings={{
          label: appName,
        }}
        farRightSettings={{
          additionalItems: getFarItems(),
          settingsSettings: {
            panelSettings: {
              titleText: intl.formatMessage(messages.settingsSettingsTitle),
              body: getSettingsBody(),
            },
          },
        }}
      />
    );
  };

  // Function to find the next focusable element outside the ms-panel
function findNextFocusableElement(panel) {
  // Assuming 'panel' is the ms-panel DOM element
  const focusableElements = 'button, [href], input, select, textarea';
  const modalFocusableElements = panel.querySelectorAll(focusableElements);
  const lastFocusableElement = modalFocusableElements[modalFocusableElements.length - 1];

  // Event listener for the last focusable element in the ms-panel
  lastFocusableElement.addEventListener('keydown', function(e) {
    const isTabPressed = (e.key === 'Tab' || e.keyCode === 9);

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) { // if shift key pressed for shift + tab combination
      return;
    } else { // if tab key is pressed
      e.preventDefault();
      // Find the first focusable element outside the ms-panel
      const outsideFocusableElements:any = Array.from(document.querySelectorAll(focusableElements)).filter(el => !panel.contains(el));
      if (outsideFocusableElements.length > 0) {
        // Set focus to the next focusable element outside the ms-panel
        const firstPivotLinkElement = outsideFocusableElements.find(el => el.classList.contains('ms-Link'));
        if (firstPivotLinkElement) {
          // Set focus to the first .ms-Pivot-link element outside the ms-panel
          firstPivotLinkElement.focus();
        }


      }
    }
  });
}

const handleTabKey = () => {
  intervalId =setInterval(() => {
const msPanel = document.querySelector('.ms-Panel'); // Replace with the correct selector for your ms-panel
  if(msPanel){

  findNextFocusableElement(msPanel);
  clearInterval(intervalId);
}
  }, 300);
}


  const renderMain = (): JSX.Element => {
    if (!isAppReady) return renderInitialHeader();
    if (!isUserLoggedIn) return renderNotLoggedInHeader();
  
    let loaderElement;
    if (isBotError) {
      loaderElement = (
        <div className='loadertitle'>
          <img src={images.fail} id="iris-title" className='botImg' alt="Iris title icon" />
          <div className='loaderbody'>
            <div className='errorBody'>{fakebot.Something_went_wrong}</div><br />
            <div className='errorblock'>{fakebot.Please_refresh}</div>
          </div>
          <button type="submit" className='refeshbtn' onClick={handleChatBotIconClick}>
            {fakebot.Refesh}
          </button>
        </div>
      );
    } else if (loaderName !== undefined && loaderName !== "") {
      loaderElement = (
        <div className='loadertitle'>
          <img src={images.Magicimage} className='botImg' id="iris-title" alt="Iris title icon" />
          <div className='loaderbody'>
            <b> {loaderName}</b><br />{fakebot.is_getting_ready_for_you} 
            <div className='loadermessage'>{fakebot.Please_wait_IRIS}</div>
          </div>
        </div>
      );
    } else {
      loaderElement = (
        <div className='loadertitle'>
          <img src={images.CoffeeLoader} className='botImg' id="iris-title" alt="Iris title icon" />
          <div className='loaderbody'>
            <b>{fakebot.Brewing_up_some_digital_conversationnmagic}</b>
            <div className='loadermessage'>{fakebot.Please_wait_IRIS}</div>
          </div>
        </div>
      );
    }

 
    

    return (
      <>
        {renderFullHeader()}
        {renderNotificationsPanel()}
        {isMCAPSChatBotStarted === true ? renderMCAPSChatBot() : null}
        <Suspense
             fallback={
               <></>
             }
           >
        <FeedbackPanel
          isOpen={isFeedbackPanelOpen}
          onDismiss={() => setIsFeedbackPanelOpen(false)}
          currentUserData={props.currentUserData}
        />
        </Suspense>
      
        {/* <AnnouncementBanner  currentUserData={props.currentUserData} /> */}
        {isBotLoader  ? (
          <div className="fakebot-container">
            <div className='loaderbox' style={{ alignItems: 'top' }}>
              <div className='header'>
                <img src="https://iris-cdn.azureedge.net/iris-ux/assets/images/iris-copilot-icon.svg" id="iris-title-icon" alt="Iris title icon" /> 
                <span className='headertitle'>{fakebot.Iris_Copilot}</span>
              </div>
              <div>
                {loaderElement}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };
  
  return renderMain();
};

export const AppHeader = withRouter(injectIntl(AppHeaderComponent));
